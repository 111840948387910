import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import image from '../img/eingewoehnung.jpg';
import TextAndImagePage from '../components/TextAndImagePage';
import Text from '../components/Text';

const FamiliarisationPage: React.FC<{ data: any }> = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <TextAndImagePage
        title={post.frontmatter.title}
        content={<Text>{post.frontmatter.text}</Text>}
        image={image}
        backLink="/konzept"
      />
    </Layout>
  );
};

export default FamiliarisationPage;

export const query = graphql`
  query FamiliarisationPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        text
      }
    }
  }
`;
